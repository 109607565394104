import InfoIcon from '@mui/icons-material/Info';
import RestoreIcon from '@mui/icons-material/RestoreFromTrash';
import { Box, Button, Divider, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch } from 'react-redux';

import theme from '../../../gatsby-theme-material-ui-top-layout/theme';
import { Project } from '../../../store/project';
import { restoreDocument } from '../../../store/project';
import { ToolbarIconButton } from '../ui/ToolbarIconButton/ToolbarIconButton';

const moment = require('moment');

const useStyles = makeStyles({
  label: {
    fontWeight: 600,
    fontSize: '1em',
    width: '100%',
    paddingLeft: '25px',
    marginTop: '0px',
    marginBottom: '0px',
    textOverflow: 'ellipsis',
  },
});

const rootStyle = {
  width: '100%',
  maxWidth: '960px',
  padding: '1px 1px',
  paddingLeft: '3px',
  paddingRight: '5px',
  background: theme.palette.background.paper,
  fontWeight: 600,
  position: 'relative',
};

const typographyStyle = {
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(1),
};

type TrashDocumentRenderProps = {
  project: Project;
};
const TrashDocumentRender: React.FC<TrashDocumentRenderProps> = ({ project }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleRestoreDocument = (workspaceId: string, projectId: string) => {
    dispatch(restoreDocument(workspaceId, projectId));
    // navigate(`/trash`);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id1 = open ? 'simple-popover' : undefined;

  return (
    <>
      {project.ruleSuites && project.ruleSuites.length > 0 ? (
        <div>
          {project.ruleSuites.map((ruleSuite) => {
            if (ruleSuite.type == 'workspace' && ruleSuite.removedAt !== null) {
              return (
                <Box sx={rootStyle} key={'tc-tdr-' + ruleSuite.id}>
                  <List key={ruleSuite.id} disablePadding aria-labelledby="nested-list-subheader" sx={rootStyle}>
                    <ListItem role={undefined} dense button sx={rootStyle}>
                      <ListItemText classes={{ primary: classes.label }} primary={ruleSuite.name} />
                      <ListItemText classes={{ primary: classes.label }} primary={ruleSuite.desc} />
                    </ListItem>
                    <div>
                      <ToolbarIconButton
                        aria-describedby={id1}
                        aria-label="Info Document"
                        onClick={handleClick}
                        title="Info"
                      >
                        {<InfoIcon fontSize="small" />}
                      </ToolbarIconButton>
                      <Button />
                      <Popover
                        id={id1}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <Typography sx={typographyStyle}>
                          RemovedAt : {moment(ruleSuite.removedAt).format('dddd, MMM Do YYYY, h:mm:ss a')}
                        </Typography>
                        <Typography sx={typographyStyle}>Restore to : {project.name}</Typography>
                      </Popover>
                    </div>

                    <ToolbarIconButton
                      aria-label="Restore Document"
                      onClick={() => handleRestoreDocument(ruleSuite.id, project.id)}
                      title="Restore"
                    >
                      {<RestoreIcon fontSize="small" />}
                    </ToolbarIconButton>

                    <Divider absolute />
                  </List>
                </Box>
              );
            }
          })}
        </div>
      ) : null}{' '}
    </>
  );
};

export { TrashDocumentRender };
