import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import theme from '../../../gatsby-theme-material-ui-top-layout/theme';
import { Project } from '../../../store/project';
import { projectSelectors } from '../../../store/project';
import { TrashLinkedProjectDocumentRender } from '../TrashLinkedProjectDocumentRender/TrashLinkedProjectDocumentRender';

const rootStyle = {
  width: '100%',
  maxWidth: '960px',
  background: theme.palette.background.paper,
  padding: '0px',
};

const indentationStyle = {
  paddingLeft: theme.spacing(3),
  paddingTop: '0 px',
  paddingBottom: '0 px',
};

type TrashLinkedProjectRenderProps = {
  parentProjectId: string;
  id: string;
  index: number;
};

const TrashLinkedProjectRender: React.FC<TrashLinkedProjectRenderProps> = ({ parentProjectId, id, index }) => {
  const [openProject, setOpenProject] = useState(false);
  const project: Project = useSelector(projectSelectors.projectById(id));
  const handleProjectClick = () => {
    setOpenProject(!openProject);
  };

  return (
    <>
      <Box sx={rootStyle}>
        <Draggable draggableId={'tc-tlpr-' + project.id} index={index}>
          {(provided: any) => (
            <List
              disablePadding
              aria-labelledby="nested-list-subheader"
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              sx={indentationStyle}
            >
              <ListItem button sx={rootStyle}>
                <ListItemText primary={project.name ? <div>{project.name} </div> : 'Untitled project'} />
                <div style={{ marginRight: '75px' }}>
                  {openProject ? (
                    <ExpandMoreIcon onClick={handleProjectClick} />
                  ) : (
                    <ChevronLeftIcon onClick={handleProjectClick} />
                  )}
                </div>
              </ListItem>
              <TrashLinkedProjectDocumentRender
                openProject={openProject}
                parentProjectId={parentProjectId}
                project={project}
              />
            </List>
          )}
        </Draggable>
      </Box>
    </>
  );
};
export { TrashLinkedProjectRender };
