import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import { Divider, IconButton, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import theme from '../../../gatsby-theme-material-ui-top-layout/theme';
import { Project } from '../../../store/project';
import {
  projectSelectors,
  restoreProject,
  subscribeToProjectUpdates,
  unsubscribeFromProjectUpdates,
} from '../../../store/project';
import { workspaceSelectors } from '../../../store/workspace';
import { TrashDocumentRender } from '../TrashDocumentRender/TrashDocumentRender';
import { TrashProjectDocumentRender } from '../TrashProjectDocumentRender/TrashProjectDocumentRender';
import { Spinner } from '../ui';
import { ToolbarIconButton } from '../ui/ToolbarIconButton/ToolbarIconButton';

const moment = require('moment');

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 600,
    fontSize: '1em',
    width: '100%',
    paddingLeft: '25px',
    textOverflow: 'ellipsis',
  },
}));

const rootStyle = {
  width: '100%',
  maxWidth: '960px',
  padding: '1px 1px',
  paddingLeft: '3px',
  paddingRight: '5px',
  background: theme.palette.background.paper,
  fontWeight: 600,
  position: 'relative',
};

const typographyStyle = {
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(1),
};

type TrashProjectRenderProps = {
  id: string;
  containerId: string | null;
};

const TrashProjectRender: React.FC<TrashProjectRenderProps> = ({ id, containerId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openProject, setOpenProject] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const project: Project = useSelector(projectSelectors.projectById(id));
  const projectTreeRootId = useSelector(projectSelectors.projectTreeRootId);
  const isLoading = useSelector(workspaceSelectors.isLoading);

  const handleProjectClick = () => {
    setOpenProject(!openProject);
  };

  useEffect(() => {
    if (id) {
      dispatch(subscribeToProjectUpdates('', id, projectTreeRootId));
    }
    return function cleanup() {
      if (id) {
        dispatch(unsubscribeFromProjectUpdates());
      }
    };
  }, [id]);

  const handleRestoreProject = (projectId: string, containerId: string | null) => {
    dispatch(restoreProject(projectId, containerId));
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id1 = open ? 'simple-popover' : undefined;

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <>
      {project.removedAt !== null ? (
        <div>
          <List disablePadding aria-labelledby="nested-list-subheader" sx={rootStyle}>
            <ListItem role={undefined} dense sx={rootStyle}>
              <ListItemText
                classes={{ primary: classes.label }}
                primary={project.name ? project.name : 'Untitled project'}
              />
              {openProject ? (
                <IconButton size="small" onClick={handleProjectClick}>
                  <ExpandMoreIcon />
                </IconButton>
              ) : (
                <IconButton size="small" onClick={handleProjectClick}>
                  <ChevronLeftIcon />
                </IconButton>
              )}

              <ToolbarIconButton aria-describedby={id1} aria-label="Info Document" onClick={handleClick} title="Info">
                <InfoOutlinedIcon fontSize="small" style={{ marginRight: '3px' }} color="secondary" />
                Info
              </ToolbarIconButton>

              <ToolbarIconButton
                aria-label="Restore Document"
                onClick={() => handleRestoreProject(project.id, containerId)}
                title="Restore"
              >
                <RestoreFromTrashOutlinedIcon fontSize="small" style={{ marginRight: '3px' }} color="secondary" />
                Restore
              </ToolbarIconButton>
            </ListItem>

            <Popover
              id={id1}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography sx={typographyStyle}>
                RemovedAt : {moment(project.removedAt).format('dddd, MMM Do YYYY, h:mm:ss a')}
              </Typography>
              <Typography sx={typographyStyle}>Restore to : My projects</Typography>
            </Popover>

            <TrashProjectDocumentRender openProject={openProject} project={project} />
            <Divider absolute />
          </List>
        </div>
      ) : (
        <TrashDocumentRender key={'tc-pdr-' + project.id} project={project} />
      )}
    </>
  );
};
export { TrashProjectRender };
