import { navigate } from '@gatsbyjs/reach-router';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useSelector } from 'react-redux';

import theme from '../../../gatsby-theme-material-ui-top-layout/theme';
import { dashboardSelector } from '../../../store/dashboard';
import { projectSelectors } from '../../../store/project';
import { TrashProjectRender } from '../TrashProjectRender/TrashProjectRender';
import { Spinner } from '../ui';
import { ToolbarIconButton } from '../ui/ToolbarIconButton/ToolbarIconButton';

const rootStyle = {
  width: '100%',
  maxWidth: '960px',
  background: theme.palette.background.paper,
  padding: '0px',
};

const TrashContainer = () => {
  const isLoadingDashboard = useSelector(dashboardSelector.isLoading);
  const isLoadingProject = useSelector(projectSelectors.isLoading);
  const activeDashboardId = useSelector(dashboardSelector.activeDashboardId);
  const activeUserEmailId = useSelector(dashboardSelector.activeUserEmailId);
  const projectIds: any = useSelector(dashboardSelector.projects(activeDashboardId || activeUserEmailId));

  if (isLoadingDashboard || isLoadingProject) {
    return <Spinner />;
  }
  const handleMyProjects = () => {
    if (activeDashboardId) {
      navigate(`/dashboard/${activeDashboardId}`);
    } else {
      navigate(`/dashboard/`);
    }
  };

  const handleTrash = () => {
    navigate(`/trash`);
  };

  return (
    <Grid container spacing={2} direction="row" justifyContent="flex-start" sx={{ marginTop: '10px' }}>
      <Grid item xs={3}>
        <Grid container spacing={2} direction="column" style={{ marginLeft: '10px' }}>
          <Grid item>
            <ToolbarIconButton aria-label="My projects" onClick={() => handleMyProjects()} title="My projects">
              <AccountBoxIcon fontSize="small" style={{ marginRight: '5px' }} />
              My projects
            </ToolbarIconButton>
          </Grid>
          <Grid item>
            <ToolbarIconButton aria-label="Trash" onClick={() => handleTrash()} title="Trash">
              <DeleteIcon fontSize="small" style={{ marginRight: '5px' }} />
              Trash
            </ToolbarIconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8} sx={rootStyle}>
        <Box sx={rootStyle}>
          {projectIds.map((id: string) => {
            return <TrashProjectRender key={id} id={id} containerId={activeDashboardId ? activeDashboardId : null} />;
          })}
        </Box>
      </Grid>
    </Grid>
  );
};
export { TrashContainer };
